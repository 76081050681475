import React from "react"
import { graphql } from "gatsby"
import Projects from "../components/Projects"
import Seo from "../components/Seo"
const ProjectsPage = () => {
  return (
    <>
      <h2>projects page</h2>
    </>
  )
}

export default ProjectsPage
